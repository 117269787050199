import React from 'react';

import { TableColumn } from '../../types/Table';
import { AccessProfile } from '../../models/AccessProfile';
import { Translated } from '../../components/UI/Core';
import { TranslatedTag } from '../../components/UI/Tag/TranslatedTag';

export const accessProfileTableColumns: Array<TableColumn<AccessProfile>> = [
  {
    Header: <Translated id="accessProfiles.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfiles.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="accessProfiles.isDefaultForVisitsTable" />,
    id: 'DefaultForVisits',
    accessor: (row) =>
      row.DefaultForVisits ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfiles.isDefaultForEmployeesTable" />,
    id: 'DefaultForEmployees',
    accessor: (row) =>
      row.DefaultForEmployees ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfiles.isDefaultForAzureAdImportTable" />,
    id: 'DefaultForAzureAdImport',
    accessor: (row) =>
      row.DefaultForAzureAdImport ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfiles.isDefaultForParkingReservationsTable" />,
    id: 'DefaultForParkingReservations',
    accessor: (row) =>
      row.DefaultForParkingReservations ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
  {
    Header: <Translated id="accessProfiles.requiresVerificationTable" />,
    id: 'RequiresVerification',
    accessor: (row) =>
      row.RequiresVerification ? (
        <TranslatedTag color="green" translationId="app.yes" />
      ) : (
        <TranslatedTag color="red" translationId="app.no" />
      ),
    disableFilters: true,
  },
];
