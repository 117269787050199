import React, { useCallback, useMemo } from 'react';
import { Form, Input, InputNumber, Space } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import { AdvancedPACSSettingsFormValues, PACSSettingsFormValues } from '../../../../models/PACSSettingFormValues';
import { ServiceConfigurationResponse } from '../../../../models/ServiceConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';

// Components
const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface AdvancedPACSSettingsProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
}

// Hook
export const useAdvancedPACSSettings = ({
  // updating,
  serviceConfigurationResponse,
}: AdvancedPACSSettingsProps) => {
  // Intl
  const intl = useIntl();

  // Form Values
  const initialValues: PACSSettingsFormValues = useMemo(
    () => ({
      Id: serviceConfigurationResponse?.ServiceConfiguration.Id,
      Name: serviceConfigurationResponse?.ServiceConfiguration.Name,
      AccessControlSystemType: serviceConfigurationResponse?.ServiceConfiguration.AccessControlSystemType,
      ManageEmployees: serviceConfigurationResponse?.ServiceConfiguration.ManageEmployees,
      ManageVisitors: serviceConfigurationResponse?.ServiceConfiguration.ManageVisitors,
      SettingsData: serviceConfigurationResponse?.SettingsData?.SettingsData ?? '{}',
      AdvancedSettings:
        (serviceConfigurationResponse?.SettingsData?.AdvancedSettings as AdvancedPACSSettingsFormValues) ?? '{}',
    }),
    [serviceConfigurationResponse]
  );

  // Components
  const AdvancedPACSSettingsForm = useCallback(
    () => (
      <>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="AccessControlSystemType" hidden>
          <Input />
        </Item>

        {/* Form Values */}

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['AdvancedSettings', 'CheckInTolerance']}
            label={<Translated id="pacsSetting.form.checkInTolerance" />}
            initialValue={0}
            extra={
              <small>
                <Translated id="pacsSetting.form.checkInToleranceExtra" />
              </small>
            }
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'form.warnings.number' }),
              },
            ]}
          >
            <InputNumber
              type="number"
              pattern="[0-9]*"
              min={0}
              step={1}
              addonAfter={<Translated id="pacsSetting.form.minutes" />}
              style={{ width: '100%' }}
            />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['AdvancedSettings', 'CheckOutTolerance']}
            label={<Translated id="pacsSetting.form.checkOutTolerance" />}
            initialValue={0}
            extra={
              <small>
                <Translated id="pacsSetting.form.checkOutToleranceExtra" />
              </small>
            }
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'form.warnings.number' }),
              },
            ]}
          >
            <InputNumber
              type="number"
              pattern="[0-9]*"
              step={1}
              min={0}
              addonAfter={<Translated id="pacsSetting.form.minutes" />}
              style={{ width: '100%' }}
            />
          </NoSidePaddingFormItem>
        </StyledSpace>
      </>
    ),
    [intl]
  );

  return useMemo(
    () => ({
      AdvancedPACSSettingsForm,
      initialValues,
    }),
    [AdvancedPACSSettingsForm, initialValues]
  );
};
