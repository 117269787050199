import React, { useCallback, useMemo } from 'react';
import { Divider, Form, Input, Space } from 'antd';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import {
  ScheidtAndBachmannFormSettings,
  ScheidtAndBachmannSettingsFormValues,
} from '../../../../models/PACSSettingFormValues';
import { ServiceConfigurationResponse } from '../../../../models/ServiceConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';

// Components
const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface ScheidtAndBachmannSettingsProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
}

// Hook
export const useScheidtAndBachmannSettings = ({
  // updating,
  serviceConfigurationResponse,
}: ScheidtAndBachmannSettingsProps) => {
  // Intl
  const intl = useIntl();

  // Form Values
  const initialValues: ScheidtAndBachmannSettingsFormValues = useMemo(
    () => ({
      Id: serviceConfigurationResponse?.ServiceConfiguration.Id,
      Name: serviceConfigurationResponse?.ServiceConfiguration.Name,
      AccessControlSystemType: serviceConfigurationResponse?.ServiceConfiguration.AccessControlSystemType,
      ManageEmployees: serviceConfigurationResponse?.ServiceConfiguration.ManageEmployees,
      ManageVisitors: serviceConfigurationResponse?.ServiceConfiguration.ManageVisitors,
      SettingsData: JSON.parse(
        serviceConfigurationResponse?.SettingsData?.SettingsData ?? '{}'
      ) as ScheidtAndBachmannFormSettings,
    }),
    [serviceConfigurationResponse]
  );

  // Components
  const ScheidtAndBachmannSettingsForm = useCallback(
    () => (
      <>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="ManageEmployees" hidden>
          <Input />
        </Item>
        <Item name="ManageVisitors" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="AccessControlSystemType" hidden>
          <Input />
        </Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'Host']}
            label={<Translated id="pacsSetting.form.host" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.host' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'Username']}
            label={<Translated id="pacsSetting.form.username" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.username' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'Password']}
            label={<Translated id="pacsSetting.form.password" />}
          >
            <Input.Password />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['SettingsData', 'ConfirmPassword']}
            label={<Translated id="pacsSetting.form.confirmPassword" />}
            dependencies={['SettingsData', 'Password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(['SettingsData', 'Password']) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(intl.formatMessage({ id: 'pacsSetting.form.warnings.confirmPassword' }))
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'FacilityName']}
            label={<Translated id="pacsSetting.form.facilityName" />}
          >
            <Input />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['SettingsData', 'FacilityList']}
            label={<Translated id="pacsSetting.form.facilityList" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.facilityList' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>
      </>
    ),
    [intl]
  );

  return useMemo(
    () => ({
      ScheidtAndBachmannSettingsForm,
      initialValues,
    }),
    [ScheidtAndBachmannSettingsForm, initialValues]
  );
};
