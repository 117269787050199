import { AccessControlSystem } from './enums/AccessControlSystemTypes';
import { AdvancedPACSSettings } from './PACSServiceConfiguration';

export interface PACSSettingsFormValues {
  Id: string | undefined;
  Name: string | undefined;
  AccessControlSystemType?: AccessControlSystem;
  ManageEmployees?: boolean;
  ManageVisitors?: boolean;
  SettingsData: any;
  AdvancedSettings?: AdvancedPACSSettings;
}

export interface AdvancedPACSSettingsFormValues {
  CheckInTolerance: number;
  CheckOutTolerance: number;
}

export interface AxisSettingsFormValues extends PACSSettingsFormValues {
  SettingsData: AxisFormSettings;
}

export interface AxisFormSettings {
  Location: string | undefined;
  Username: string | undefined;
  Password: string | undefined;
  IgnoreSslErrors: boolean | undefined;
}

export interface PaxtonSettingsFormValues extends PACSSettingsFormValues {
  SettingsData: PaxtonFormSettings;
}

export interface PaxtonFormSettings {
  Location: string | undefined;
  Username: string | undefined;
  Password: string | undefined;
  ClientId: string | undefined;
  IgnoreSslErrors: boolean | undefined;
  VisitorDepartmentName: string | undefined;
  EmployeeDepartmentName: string | undefined;
}

export interface AeosSettingsFormValues extends PACSSettingsFormValues {
  SettingsData: AeosFormSettings;
}

export interface AeosFormSettings {
  Location: string | undefined;
  Username: string | undefined;
  Password: string | undefined;
  ScheduleId: string | undefined;
  IgnoreSslErrors: boolean | undefined;
  AvailableSchedulesJson: string | undefined;
}

export interface AeosTimeSchedule {
  Id: string | undefined;
  Name: string | undefined;
}

export interface SaltoSpaceSettingsFormValues extends PACSSettingsFormValues {
  SettingsData: SaltoSpaceFormSettings;
}

export interface SaltoSpaceFormSettings {
  Host: string | undefined;
  Port: number | undefined;
  UseHttps: boolean | undefined;
  AuthenticationType: number | undefined;
  Username: string | undefined;
  Password: string | undefined;
  ClientId: string | undefined;
  IgnoreSslErrors: boolean | undefined;
}

export enum SaltoSpaceAuthentication {
  Stp = 0,
  HttpBasic = 1,
  HttpWithKey = 2,
}

export interface SaltoSpaceAuthenticationType {
  Name: string;
  Value: number;
}

export const SaltoSpaceAuthenticationTypes: SaltoSpaceAuthenticationType[] = [
  {
    Name: 'STP',
    Value: 0,
  },
  {
    Name: 'HTTP Basic',
    Value: 1,
  },
  {
    Name: 'HTTP With key',
    Value: 2,
  },
];

export interface SaltoKsSettingsFormValues extends PACSSettingsFormValues {
  SettingsData: SaltoKsFormSettings;
}

export interface SaltoKsFormSettings {
  ConnectUrl: string | undefined;
  IdentityUrl: string | undefined;
  ClientId: string | undefined;
  Secret: string | undefined;
  Username: string | undefined;
  Password: string | undefined;
  SiteId: string | undefined;
  RoleId: string | undefined;
  WriteEmail: boolean | undefined;
  AvailableSitesJson: string | undefined;
}

export interface SaltoKsSite {
  Id: string | undefined;
  Name: string | undefined;
  Roles: Array<SaltoKsRole> | undefined;
}

export interface SaltoKsRole {
  Id: string | undefined;
  Name: string | undefined;
}

export interface ScheidtAndBachmannSettingsFormValues extends PACSSettingsFormValues {
  SettingsData: ScheidtAndBachmannFormSettings;
}

export interface ScheidtAndBachmannFormSettings {
  Host: string | undefined;
  Username: string | undefined;
  Password: string | undefined;
  FacilityName: string | undefined;
  FacilityList: string | undefined;
}

export interface GenetecSettingsFormValues extends PACSSettingsFormValues {
  SettingsData: GenetecFormSettings;
}

export interface GenetecFormSettings {
  Location: string | undefined;
  Username: string | undefined;
  Password: string | undefined;
}

export interface ILoqSettingsFormValues extends PACSSettingsFormValues {
  SettingsData: ILoqFormSettings;
}

export interface ILoqFormSettings {
  Location: string | undefined;
  Username: string | undefined;
  Password: string | undefined;
  CustomerCode: string | undefined;
}
