export enum IdentityRole {
  ApplicationUser = 'ApplicationUser',
  KioskUser = 'KioskUser',
  VerificationManager = 'VerificationManager',
  TenantAdministrator = 'TenantAdministrator',
  Reservations = 'Reservations',
}

export interface IdentityRoleType {
  NameId: string;
  Color: string;
  IdentityRole: IdentityRole;
}

export const IdentityRoles: IdentityRoleType[] = [
  {
    NameId: 'identityUsers.roles.applicationUser',
    Color: 'green',
    IdentityRole: IdentityRole.ApplicationUser,
  },
  {
    NameId: 'identityUsers.roles.reservations',
    Color: 'purple',
    IdentityRole: IdentityRole.Reservations,
  },
  {
    NameId: 'identityUsers.roles.kioskUser',
    Color: 'blue',
    IdentityRole: IdentityRole.KioskUser,
  },
  {
    NameId: 'identityUsers.roles.tenantAdministrator',
    Color: 'orange',
    IdentityRole: IdentityRole.TenantAdministrator,
  },
  {
    NameId: 'identityUsers.roles.verificationManager',
    Color: 'lime',
    IdentityRole: IdentityRole.VerificationManager,
  },
];
