import { Tenant } from './Tenant';
import { ScheduleConfigurationJobType } from './enums/ScheduleConfigurationTypes';

export interface ScheduleSettingsFormValues {
  Id: string | undefined;
  Name: string | undefined;
  Tenant: Tenant | undefined;
  Enabled: boolean | undefined;
  Interval: number | undefined;
  ScheduleConfigurationType?: ScheduleConfigurationJobType;
  CustomConfiguration: any;
}

export interface ImportAzureAdUsersSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: ImportAzureAdUsersSettings;
}

export interface ImportAzureAdUsersSettings {
  AzureTenantId: string | undefined;
}

export interface CheckOutVisitorsSettingsSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: CheckOutVisitorsSettings;
}

export interface CheckOutVisitorsSettings {
  Days: number | undefined;
}

export interface RemoveRestrictedAccessProfilesSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: RemoveRestrictedAccessProfilesSettings;
}

export interface RemoveRestrictedAccessProfilesSettings {
  NotifyPerson: boolean | undefined;
}

export interface FetchInvitationEmailsSettingsFormValues extends ScheduleSettingsFormValues {
  CustomConfiguration: FetchInvitationEmailsSettings;
}

export interface FetchInvitationEmailsSettings {
  AzureTenantId: string | undefined;
}

export interface ScheduleInterval {
  Value: number;
  Translation: string;
}

export const ScheduleIntervals: ScheduleInterval[] = [
  {
    Value: 300,
    Translation: 'scheduleConfigurations.form.fiveMinutes',
  },
  {
    Value: 600,
    Translation: 'scheduleConfigurations.form.tenMinutes',
  },
  {
    Value: 900,
    Translation: 'scheduleConfigurations.form.fifteenMinutes',
  },
  {
    Value: 1800,
    Translation: 'scheduleConfigurations.form.halfHour',
  },
  {
    Value: 3600,
    Translation: 'scheduleConfigurations.form.hour',
  },
  {
    Value: 86400,
    Translation: 'scheduleConfigurations.form.day',
  },
];
