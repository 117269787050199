import React, { useCallback, useMemo } from 'react';
import { Divider, Form, Input, Select, Space, Switch } from 'antd';
import styled from 'styled-components';

// Models
import FormItemLabel from 'antd/es/form/FormItemLabel';
import {
  RemoveRestrictedAccessProfilesSettings,
  RemoveRestrictedAccessProfilesSettingsFormValues,
  ScheduleIntervals,
} from '../../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../../models/ScheduleConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;

const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;

const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface RemoveRestrictedAccessProfilesSettingsProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

// Hook
export const useRemoveRestrictedAccessProfilesSettings = ({
  // updating,
  scheduleConfigurationResponse,
}: RemoveRestrictedAccessProfilesSettingsProps) => {
  // Form Values
  const initialValues: RemoveRestrictedAccessProfilesSettingsFormValues = useMemo(
    () => ({
      Id: scheduleConfigurationResponse?.Id,
      Name: scheduleConfigurationResponse?.Name,
      Enabled: scheduleConfigurationResponse?.Enabled,
      Tenant: scheduleConfigurationResponse?.Tenant,
      Interval: scheduleConfigurationResponse?.Interval,
      CustomConfiguration: JSON.parse(
        scheduleConfigurationResponse?.CustomConfiguration ?? '{}'
      ) as RemoveRestrictedAccessProfilesSettings,
    }),
    [scheduleConfigurationResponse]
  );

  // Components
  const RemoveRestrictedAccessProfilesSettingsForm = useCallback(
    () => (
      <>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="ScheduleConfigurationType" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>

        <Form.Item name={['Tenant', 'CompanyId']} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={['Tenant', 'LocationId']} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={['Tenant', 'BusinessClientId']} hidden>
          <Input />
        </Form.Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name="Interval"
            label={<Translated id="scheduleConfigurations.form.interval" />}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select placeholder={<Translated id="scheduleConfigurations.form.interval" />}>
              {ScheduleIntervals?.map((interval) => (
                <Option key={interval.Value} value={interval.Value}>
                  <Translated id={interval.Translation} />
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>
        </StyledSpace>
        <Space>
          <FormItemLabel label={<Translated id="scheduleConfigurations.form.enabled" />} prefixCls="cls" />
          <NoMarginBottomFormItem name="Enabled" valuePropName="checked">
            <Switch
              checkedChildren={<Translated id="scheduleConfigurations.form.active" />}
              unCheckedChildren={<Translated id="scheduleConfigurations.form.disabled" />}
            />
          </NoMarginBottomFormItem>
        </Space>
        <Divider />
        <Space>
          <FormItemLabel label={<Translated id="scheduleConfigurations.form.notifyPerson" />} prefixCls="cls" />
          <NoMarginBottomFormItem name={['CustomConfiguration', 'NotifyPerson']} valuePropName="checked">
            <Switch
              checkedChildren={<Translated id="scheduleConfigurations.form.active" />}
              unCheckedChildren={<Translated id="scheduleConfigurations.form.disabled" />}
            />
          </NoMarginBottomFormItem>
        </Space>
      </>
    ),
    []
  );

  return useMemo(
    () => ({
      RemoveRestrictedAccessProfilesSettingsForm,
      initialValues,
    }),
    [RemoveRestrictedAccessProfilesSettingsForm, initialValues]
  );
};
