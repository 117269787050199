import React, { useCallback, useMemo } from 'react';
import { Divider, Form, Input, Select, Space, Switch } from 'antd';
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import { AeosFormSettings, AeosSettingsFormValues, AeosTimeSchedule } from '../../../../models/PACSSettingFormValues';
import { ServiceConfigurationResponse } from '../../../../models/ServiceConfiguration';

// Components
import { Translated } from '../../../../components/UI/Core';

// Components
const { Item } = Form;
const { Option } = Select;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0px;

  & .ant-space-item {
    width: 100%;
  }
`;

// Props
interface AeosSettingsProps {
  serviceConfigurationResponse: ServiceConfigurationResponse | null;
}

// Hook
export const useAeosSettings = ({
  // updating,
  serviceConfigurationResponse,
}: AeosSettingsProps) => {
  // Intl
  const intl = useIntl();

  // Form Values
  const initialValues: AeosSettingsFormValues = useMemo(
    () => ({
      Id: serviceConfigurationResponse?.ServiceConfiguration.Id,
      Name: serviceConfigurationResponse?.ServiceConfiguration.Name,
      AccessControlSystemType: serviceConfigurationResponse?.ServiceConfiguration.AccessControlSystemType,
      ManageEmployees: serviceConfigurationResponse?.ServiceConfiguration.ManageEmployees,
      ManageVisitors: serviceConfigurationResponse?.ServiceConfiguration.ManageVisitors,
      SettingsData: JSON.parse(serviceConfigurationResponse?.SettingsData?.SettingsData ?? '{}') as AeosFormSettings,
    }),
    [serviceConfigurationResponse]
  );

  const availableTimeSchedules: Array<AeosTimeSchedule> = useMemo(() => {
    const settings = JSON.parse(serviceConfigurationResponse?.SettingsData?.SettingsData ?? '{}') as AeosFormSettings;

    return JSON.parse(settings.AvailableSchedulesJson ?? '[]');
  }, [serviceConfigurationResponse]);

  // Components
  const AeosSettingsForm = useCallback(
    () => (
      <>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="ManageEmployees" hidden>
          <Input />
        </Item>
        <Item name="ManageVisitors" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="AccessControlSystemType" hidden>
          <Input />
        </Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'Location']}
            label={<Translated id="pacsSetting.form.host" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.host' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['SettingsData', 'Username']}
            label={<Translated id="pacsSetting.form.username" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'pacsSetting.form.warnings.username' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'Password']}
            label={<Translated id="pacsSetting.form.password" />}
          >
            <Input.Password />
          </NoSidePaddingFormItem>

          <NoSidePaddingFormItem
            name={['SettingsData', 'ConfirmPassword']}
            label={<Translated id="pacsSetting.form.confirmPassword" />}
            dependencies={['SettingsData', 'Password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(['SettingsData', 'Password']) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(intl.formatMessage({ id: 'pacsSetting.form.warnings.confirmPassword' }))
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['SettingsData', 'ScheduleId']}
            label={<Translated id="pacsSetting.form.timeschedule" />}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select placeholder={<Translated id="pacsSetting.form.timeschedule" />}>
              {availableTimeSchedules.map((timeSchedule) => (
                <Option key={timeSchedule.Id} value={timeSchedule.Id}>
                  {timeSchedule.Name}
                </Option>
              ))}
            </Select>
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Divider />

        <Space>
          <FormItemLabel label={<Translated id="pacsSetting.form.ignoreSslErrors" />} prefixCls="cls" />
          <NoMarginBottomFormItem name={['SettingsData', 'IgnoreSslErrors']} valuePropName="checked">
            <Switch
              checkedChildren={<Translated id="pacsSetting.form.active" />}
              unCheckedChildren={<Translated id="pacsSetting.form.disabled" />}
            />
          </NoMarginBottomFormItem>
        </Space>
      </>
    ),
    [intl, availableTimeSchedules]
  );

  return useMemo(
    () => ({
      AeosSettingsForm,
      initialValues,
    }),
    [AeosSettingsForm, initialValues]
  );
};
