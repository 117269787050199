import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';

// Models
import { useDispatch } from 'react-redux';

// Components
import { Translated } from '../../components/UI/Core';
import { useAppSelector } from '../App/useRedux';
import { updateCustomTableFilters } from '../../store/IdentityUsers/IdentityUsers.redux';
import { IdentityRole } from '../../models/enums/IdentityRoles';

// Data
const options = [
  {
    value: IdentityRole.ApplicationUser,
    label: <Translated id="identityUsers.roles.applicationUser" />,
  },
  {
    value: IdentityRole.KioskUser,
    label: <Translated id="identityUsers.roles.kioskUser" />,
  },
  {
    value: IdentityRole.VerificationManager,
    label: <Translated id="identityUsers.roles.verificationManager" />,
  },
  {
    value: IdentityRole.Reservations,
    label: <Translated id="identityUsers.roles.reservations" />,
  },
  {
    value: IdentityRole.TenantAdministrator,
    label: <Translated id="identityUsers.roles.tenantAdministrator" />,
  },
];

// Hook
export const useIdentityUserTableFilter = () => {
  // State
  const dispatch = useDispatch();
  const { tableCustomFilters: customTableFilters } = useAppSelector(({ identityUsers }) => identityUsers);

  // Components
  const FilterSelect = useCallback(
    () => (
      <Select
        mode="multiple"
        allowClear
        placeholder={<Translated id="identityUsers.roles" />}
        value={customTableFilters.roles}
        options={options}
        onChange={(value) => dispatch(updateCustomTableFilters({ ...customTableFilters, roles: value }))}
        style={{ minWidth: 140, marginRight: 16 }}
      />
    ),
    [dispatch, customTableFilters]
  );

  // Hook response
  return useMemo(
    () => ({
      FilterSelect,
      customTableFilters,
    }),
    [FilterSelect, customTableFilters]
  );
};
